import React, { Component, Fragment } from "react";

import SocketHandler from "../../components/SocketHandler/SocketHandler.container";
import AgentTimeLogContainer from "../../components/AgentTimeLogManager/AgentTimeLog.container";
import AgentStatisticsManagerContainer from "../../components/AgentStatistics/AgentStatisticsManager";
import InactiveRecordsCleanup from "../../components/InactiveRecords/InactiveRecordsCleanup";

import UserMenu from "../../components/ConversationTopBar/UserMenu";
import RecipientNavBar from "../../components/ConversationTopBar/RecipientNavBar";

import ConversationContainerV2 from "../../components/ConversationV2/Conversation.container";
import LimboRecordsInterval from "../../components/LimboRecordsInterval/LimboRecordsInterval";
import TimedListReload from "../../components/TimedListReload";

class SurveyComponent extends Component {
	/**
	 * Calculates the new and ongoing conversation counts given an object of conversations
	 *  This method is made static so `getDerivedStateFromProps` can access it
	 *
	 * @param {Object} campaignNumbersList The object containing all of the conversations passed from props
	 * @returns {Array} A tuple-like array of counts. 0th index holds the new conversation count, 1st array hold ongoing conversation count
	 */
	getConversationCounts(campaignNumbersList) {
		const convoCounts = [0, 0];
		for (const phone in campaignNumbersList) {
			const recipient = campaignNumbersList[phone];

			if (recipient.terminating) continue;
			const conversation = recipient.conversation;

			// The zero index of totalCounts holds the new conversation count, the first index holds ongoing conversation count
			// Because Number(false) === 0 and Number(true) === 1
			convoCounts[Number(!!conversation.length)] += 1;
		}
		return convoCounts;
	}

	componentDidMount() {
		this.props.updateActionableConversationCount();
		// logs out the user on browser event
		if (process.env.NODE_ENV === "production") {
			// This event is depricated. What are we trying to acompolish here?
			window.addEventListener("unload", (e) => {
				e.preventDefault();
				console.log("Acutally Unloading", e);
				this.props.logout();
				return (e.returnValue = "Are you sure you want to close?");
			});
		}
	}

	render() {
		const {
			script,
			prompt: prompts,
			selectedRecipientPhone,
			numbers,
			initialconditionals,
			campaignStatus,
			jwt,
			campaignid,
			limit_new_first_attempts_hourly: hourlyLimitIsActive,
		} = this.props;
		const selectedConversation = numbers[selectedRecipientPhone];
		const [newConversationCount, ongoingConversationCount] =
			this.getConversationCounts(numbers);
		return (
			<div className="dcm panel vertical">
				{this.props.twilioroom && <SocketHandler />}
				{campaignStatus !== "sandbox" &&
					this.props.permissionlevel > 0 && <AgentTimeLogContainer />}

				{campaignStatus !== "sandbox" &&
					this.props.permissionlevel > 0 && (
						<AgentStatisticsManagerContainer />
					)}

				{hourlyLimitIsActive && <TimedListReload />}

				<InactiveRecordsCleanup />
				<LimboRecordsInterval />
				{selectedConversation && (
					<RecipientNavBar
						recipientName={selectedConversation.name}
						leftChildren={
							<Fragment>
								<div
									className={`navbar-item ${
										!selectedConversation.conversation
											.length
											? ""
											: "has-text-success"
									}`}
								>
									Ongoing ({ongoingConversationCount})
								</div>
								<div
									className={`navbar-item ${
										selectedConversation.conversation.length
											? ""
											: "has-text-info"
									}`}
								>
									New ({newConversationCount})
								</div>
							</Fragment>
						}
						rightChildren={
							<UserMenu
								phone={selectedRecipientPhone}
								timedFinishConversation={
									this.props.timedFinishConversation
								}
								addToPermanentOptOutAndTerminateConversation={
									this.props
										.addToPermanentOptOutAndTerminateConversation
								}
							/>
						}
					/>
				)}
				<ConversationContainerV2
					selectedPhone={selectedRecipientPhone}
					script={script}
					prompts={prompts}
					initialConditionals={initialconditionals}
					addTexterAppEvent={this.props.addTexterAppEvent}
					addToPermanentOptOutAndTerminateConversation={
						this.props.addToPermanentOptOutAndTerminateConversation
					}
					campaignId={campaignid}
					jwt={jwt}
				/>
			</div>
		);
	}
}

SurveyComponent.defaultProps = {
	numbers: [],
};

export default SurveyComponent;
