import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { StatusBar } from "./StatusBar.component";
import { logout } from "../redux/auth/actions";

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ logoutThunkCallback: logout }, dispatch);

const mapStateToProps = (state) => ({
	selectedCampaignName: state.campaign.name,
	campaignStatus: state.campaign.active,
	campaignid: state.auth.campaignid,
	userid: state.auth.userid,
	newRecordsMsg: state.recipients.newRecordsMsg,
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
