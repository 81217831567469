import React from "react";
import RecurringCallbackManager from "../common/RecurringCallbackManager";
import { getMoreListHourlyLimit } from "../redux/recipients/thunks";
import { reduxConnectedComponent } from "../util/reduxConnectedHOC";

const MAX_TIME_MINUTES_SINCE_LASTRUN = 15;

const GetMoreListInterval = ({ getMoreListHourlyLimit }) => {

  return (
	<RecurringCallbackManager
		intervalTime={1}
		intervalName="getMoreList"
		task={() => getMoreListHourlyLimit(MAX_TIME_MINUTES_SINCE_LASTRUN*60*1000)}
    // executeTaskAtStart={true}
	/>
)};

const TimedListReload = reduxConnectedComponent(
	GetMoreListInterval,
  undefined,
	{ getMoreListHourlyLimit }
);
export default TimedListReload;
