import { Component } from "react";
import { minuteToMilliseconds, setIntervalCustom } from "../util/helper";
import PropTypes from "prop-types";

/**
 * Handle's creating and removing an interval, and provides
 * options for behaviour.
 * 
 * Since there's multiple intervals now I made this generic
 * 
 * @param {object} props - The props object.
 * @param {number} props.intervalTime - Interval between calls in Minutes.
 * @param {string} props.intervalName - The name stored in localStorage to keep track of the interval.
 * @param {boolean} [props.executeTaskAtStart=false] - If true, the callback will be executed on mount.
 * @param {function} props.task - The function callback that the interval will invoke.
 * @returns {JSX.Element} - The rendered component.
 */
class RecurringCallbackManager extends Component {
  componentDidMount() {
    // console.log(this.props);
    // Start the 60 second timer to a agent hour charge if campaign is active
    if (this.props.executeTaskAtStart === true) {
      // console.log("task executed");
      this.props.task();
    }
    this.interval = this.startRecurringTask(
      minuteToMilliseconds(this.props.intervalTime)
    );
  }

  componentWillUnmount() {
    // Stop charging agent hours
    this.clearTaskInterval();
  }

  clearTaskInterval = () => {
    // Remove any previously set intervals
    let prevId = localStorage.getItem(this.props.intervalName);
    if (prevId) {
      window.clearInterval(parseInt(prevId));
    }
  };

  startRecurringTask = (seconds) => {
    this.clearTaskInterval();

    const intervalid = setIntervalCustom(this.props.task, seconds);
    // save to localstorage
    localStorage.setItem(this.props.intervalName, intervalid);
    // console.log("Interval set", intervalid)
    return intervalid;
  };

  render = () => null;
}

RecurringCallbackManager.propTypes = {
  task: PropTypes.func.isRequired,
  intervalName: PropTypes.string.isRequired,
  intervalTime: PropTypes.number.isRequired,
  executeTaskAtStart: PropTypes.bool
};

export default RecurringCallbackManager;
